<template>
    <div :style="{'direction': $vs.rtl ? 'rtl' : 'ltr'}">
      <feather-icon icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editRecord" />
      <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 hover:text-danger cursor-pointer" @click="confirmDeleteRecord" />
    </div>
</template>

<script>
export default {
  name: 'CellRendererActions',
  methods: {
    editRecord () {
      this.$router.push(`/apps/assurances/assurance-edit/${this.params.data._id}`).catch(() => {})
    },
    confirmDeleteRecord () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirm Delete',
        text: `Voulez-vous supprimer "${this.params.data.Nom_Complet}"`,
        acceptText: 'Delete',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$store.dispatch('assurance/removeAssurance', this.params.data._id)
        .then(() => { 
          this.showDeleteSuccess()
        })
        .catch(err => { console.error(err) })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'success',
        title: 'Assurance supprimer',
        text: 'L\'assurance selectionné a été supprimé avec success'
      })
    }
  }
}
</script>
